<template>
  <label v-bind:for="component_id" v-if="component_meta.component.properties.show_label == 'Y'">
    {{ component_meta.label }}：
  </label>
  <span class="tip" v-if="component_meta.component.properties.show_label == 'Y'">{{ component_meta.tip }}</span>

  <input type="number" class="form-control"
    v-bind:class="{ 'is-invalid': component_message != '' && component_message != undefined }" v-bind:id="component_id"
    v-bind:name="component_meta.variable" v-bind:value="component_value" @change="input_event" />
  <div class="invalid-mssage">{{ component_message }}</div>
</template>

<script>
export default {
  props: ['field', 'value', 'message'],
  emits: ['input'],
  data() {

    return {
      // 元件識別碼
      component_id: 'number-' + Math.random().toString().substring(2),

      // 欄位資訊
      component_meta: this.field,

      // 元件值
      component_value: this.value,

      // 元件訊息
      component_message: this.message
    }
  },
  watch: {
    message: function (new_value, _) {
      _;
      this.component_message = new_value;

    }
  },
  methods: {
    input_event: function ($event) {
      this.component_value = $event.target.value;
      this.$emit('input', $event);
      this.validate(this.component_value);
    },
    validate: function (value) {

      this.component_message = '';

      // 是否必填
      if (this.component_meta.component.properties.required == 'Y' && value == '') {
        this.component_message = '必填';
      }

      let max = this.component_meta.component.properties.max;
      let min = this.component_meta.component.properties.min;

      if (max != '' && max != undefined && max != null && max != 'null' && parseInt(this.component_value) > parseInt(max)) {
        this.component_message = '超過最大值(最大值為' + max + ')';
      }
      else if (min != '' && min != undefined && min != null && min != 'null' && parseInt(this.component_value) < parseInt(min)) {
        this.component_message = '超過最小值(最小值為' + min + ')';
      }
      else {
        this.component_message = '';
      }

      return this.component_message;
    },
  }
}
</script>

<style>
span.tip {
  color: green;
}

div.invalid-mssage {
  color: red;
  font-size: 12px;
  margin-top: 3px;
}
</style>